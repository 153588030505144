<template>
  <div class="c-input" :class="{ hasErr: errMsg }">
    <slot />
    <input
      :type="inputType"
      :placeholder="placeholder"
      :value="value"
      v-model="inputedVal"
      @input="$emit('update:value', $event.target.value)"
      @blur="(e) => blurFunc(e)"
    />
    <span
      v-if="validation && tooltip"
      class="c-inputTooltip"
      :class="{
        isOk: isValid,
      }"
    >
      {{ tooltipMsg }}
    </span>
    <p v-if="errMsg" class="c-input__errMsg">{{ errMsg }}</p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
    inputType: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    validation: {
      type: Function,
    },
    validateArg: {
      type: [String, Number, Boolean, Array],
    },
    errMsg: {
      type: String,
    },
    blur: {
      type: Function,
    },
    change: {
      type: Function,
    },
  },
  data() {
    return {
      inputedVal: this.value,
      isValid: false,
    };
  },
  watch: {
    value() {
      this.inputedVal = this.value;
    },
    inputedVal() {
      this.validationSet();
      if (this.change) {
        this.change();
      }
    },
  },
  computed: {
    tooltipMsg() {
      return this.isValid ? "OK" : this.tooltip;
    },
  },
  methods: {
    changeHandler() {
      if (this.validateArg) {
        this.isValid = this.validation(this.inputedVal, this.validateArg);
      } else {
        this.isValid = this.validation(this.inputedVal);
      }
    },
    validationSet() {
      this.changeHandler();
      this.$emit("update:isValid", this.isValid);
      if (this.isValid) {
        this.$emit("update:errMsg", "");
      }
    },

    blurFunc(e) {
      if (this.blur) {
        this.blur(e);
      }
    },
  },
  mounted() {
    this.validationSet();
  },
};
</script>
