<template>
  <div class="c-modal__foot" :class="{ disabled: processing }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ModalFooterButtons",
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
  }
}
</script>