export default {
    methods: {
        // 任意メール削除
        removeAnyMailHandler(anyMailIndex) {
            this.form.entrustDeliveryConfirmation.anyMailList.splice(anyMailIndex, 1)
            this.validate.anyMailList.splice(anyMailIndex, 1)
        },
        // 任意メール追加
        addAnyMailHandler() {
            this.form.entrustDeliveryConfirmation.anyMailList.push(null)
            this.validate.anyMailList.push({ isValid: true, errMsg: "" })
        },
    },
};