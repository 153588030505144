import isEmptyLine from "@/pages/entrust/common/checkLine.js";

export default {
    data() {
        return {
            csvDisposalErrorMsgs: [],
        };
    },
    methods: {
        removeDisposalUnitPriceFile() {
            this.form.entrustDisposalCompanyInfo.disposalUnitPriceFileName = null;
            this.form.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList = [];
            this.csvDisposalErrorMsgs = [];
        },
        fileChangeDisposal(e) {
            if (!e.target.files[0]) return;

            const file = e.target.files[0] || e.dataTransfer.files[0];
            if (!file.type.match("text/csv")) {
                alert("CSVファイルを選択してください");
                return;
            } else {
                this.form.entrustDisposalCompanyInfo.disposalUnitPriceFileName = file.name;
            }

            const unitPriceRegexp = new RegExp(/^([+-]?\d{1,8}|0)(\.\d{1,3})?$/);
            const entrustWasteInfoIndexRegexp = new RegExp("[+]?\\d+");
            const reader = new FileReader();
            reader.readAsText(file, 'Shift_JIS');
            const loadFunc = () => {
                // エラーメッセージの初期化
                this.csvDisposalErrorMsgs = [];
                this.form.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList = [];
                const tmpList = [];
                const lines = reader.result.split(/\r\n|\n/);
                for (let i = 0; i < lines.length; i++) {
                    let element = lines[i]

                    // 空行ならそれ以後無視する
                    if (isEmptyLine(element)) break;

                    // フォーマットチェック
                    const officeData = element.split(",");
                    if (officeData.length !== 5) {
                        const msg = `${i + 1}行目のフォーマットに誤りがあります。以下のフォーマットで作成して下さい。\n`
                            + "排出事業場名称,廃棄物情報番号,廃棄物種類名称,単価（円）,単価単位";
                        this.csvDisposalErrorMsgs.push(msg);
                        return;
                    }

                    // バリデーションチェック
                    const data = {
                        generateStoreName: officeData[0],
                        entrustWasteInfoIndex: officeData[1],
                        jwnetItemTypeName: officeData[2],
                        unitPrice: officeData[3],
                        wasteUnitTypeName: officeData[4],
                    };
                    // 排出事業場名称
                    if (!data.generateStoreName) {
                        this.csvDisposalErrorMsgs.push(`${i + 1}行目エラー : 排出事業場名称を入力してください。\n`);
                    }
                    // 廃棄物種類名称
                    if (!data.jwnetItemTypeName) {
                        this.csvDisposalErrorMsgs.push(`${i + 1}行目エラー : 廃棄物種類名称を入力してください。\n`);
                    }
                    // 廃棄物情報番号
                    if (!data.entrustWasteInfoIndex) {
                        this.csvDisposalErrorMsgs.push(`${i + 1}行目エラー : 廃棄物情報番号を入力してください。\n`);
                    } else if (!Math.sign(data.entrustWasteInfoIndex) || !entrustWasteInfoIndexRegexp.test(data.entrustWasteInfoIndex) || data.entrustWasteInfoIndex < 1) {
                        // 廃棄物情報番号 整数、>= 1チェック
                        this.csvDisposalErrorMsgs.push(`${i + 1}行目エラー : 廃棄物情報番号は1以上の整数で入力してください。\n`);
                    }
                    // 単価（円）
                    if (!data.unitPrice) {
                        this.csvDisposalErrorMsgs.push(`${i + 1}行目エラー : 単価を入力してください。\n`);
                    } else if (!Math.sign(data.unitPrice)) {
                        this.csvDisposalErrorMsgs.push(`${i + 1}行目エラー : 単価は数値で入力してください。\n`);
                    } else if (!unitPriceRegexp.test(data.unitPrice)) {
                        this.csvDisposalErrorMsgs.push(`${i + 1}行目エラー : 単価は整数8桁、小数点1桁、小数部3桁で入力してください。\n`);
                    }
                    // 単価単位
                    if (!data.wasteUnitTypeName) {
                        this.csvDisposalErrorMsgs.push(`${i + 1}行目エラー : 単価単位を入力してください。\n`);
                    }
                    if (this.csvDisposalErrorMsgs.length === 0) {
                        tmpList.push(data);
                    }
                };

                // フォーム用データに加工
                if (this.csvDisposalErrorMsgs.length === 0) {
                    this.form.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList  = tmpList;
                }
            };
            reader.onload = loadFunc;
        },
    },
};
