<template>
  <div class="c-input" :class="{ hasErr: errMsg }">
    <textarea
      :placeholder="placeholder"
      :rows="rows"
      v-model="inputedVal"
      @input="$emit('update:value', $event.target.value)"
    ></textarea>
    <span v-if="validation && tooltip" class="c-inputTooltip">
      {{ tooltipMsg }}
    </span>
    <p v-if="errMsg" class="c-input__errMsg">{{ errMsg }}</p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    rows: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    validation: {
      type: Function,
    },
    errMsg: {
      type: String,
    },
    change: {
      type: Function,
    },
  },
  data() {
    return {
      inputedVal: this.value,
      isValid: false,
    };
  },
  watch: {
    value() {
      this.inputedVal = this.value;
    },
    inputedVal() {
      this.validationSet();
      if (this.change) {
        this.change();
      }
    },
  },
  computed: {
    tooltipMsg() {
      return this.isValid ? "OK" : this.tooltip;
    },
  },
  methods: {
    changeHandler() {
      if (this.validation) {
        this.isValid = this.validation(this.inputedVal);
      }
    },
    validationSet() {
      this.changeHandler();
      this.$emit("update:isValid", this.isValid);
      if (this.isValid) {
        this.$emit("update:errMsg", "");
      }
    },
  },
  mounted() {
    this.validationSet();
  },
};
</script>
