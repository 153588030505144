export default {
    methods: {
        // 全角であるか確認
        isFullWidth(c) {
            return ((c >= 0x0 && c < 0x81) || (c === 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) ? false : true;
        },

        // 全角・半角によってbyteをカウントする
        countByte(str) {
            if (!str) return 0;

            let byteSum = 0;
            for(let i = 0; i < str.length; i++) {
                const c = str.charCodeAt(i);
                const byteSize = this.isFullWidth(c) ? 2 : 1;
                byteSum += byteSize;
            }
            return byteSum;
        },
    },
};