<template>
  <div>
    <div class="c-select" :class="[{ hasErr: errMsg }, formSize]">
      <slot name="label" />
      <select v-model="inputedVal" :name="name">
        <option
          v-if="placeholder"
          value=""
          disabled
          selected
          style="display: none"
        >
          {{ placeholder }}
        </option>
        <slot></slot>
      </select>
      <span class="c-select__icon"></span>
    </div>
    <p v-if="errMsg" class="c-input__errMsg">{{ errMsg }}</p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Object],
    },
    placeholder: {
      type: String,
    },
    validation: {
      type: Function,
    },
    name: {
      type: String,
    },
    formSize: {
      type: String,
    },
    errMsg: {
      type: String,
    },
    change: {
      type: Function,
    },
  },
  data() {
    return {
      inputedVal: this.value,
      isValid: false,
    };
  },
  watch: {
    value() {
      this.inputedVal = this.value;
    },
    formSize() {
      console.log('formSize')
      console.log(this.formSize)
    },
    inputedVal() {
      this.$emit("update:value", this.inputedVal);
      this.validationSet();
      if (this.change) {
        this.change();
      }
    },
  },
  methods: {
    validationSet() {
      if (this.validation) {
        this.isValid = this.validation(this.inputedVal);
      }
      this.$emit("update:isValid", this.isValid);
      if (this.isValid) {
        this.$emit("update:errMsg", "");
      }
    },
  },
  mounted() {
    this.validationSet();
    this.$emit("update:value", this.inputedVal);
  },
};
</script>
